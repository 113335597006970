import React, { useEffect, useState } from "react";

function JobOpenings() {
  const [jobs, setJobs] = useState();
  const [filters, setFilters] = useState([]);
  const jobList = [];
  useEffect(() => {
    if (!jobs) {
      const headder = [
        "id",
        "dep",
        "badge",
        "role",
        "time",
        "location",
        "type",
        "skills",
      ];
      fetch(
        `https://docs.google.com/spreadsheets/d/e/2PACX-1vRxyOf3CATEKdniqxDbrmtBOwm2B4suliaj4MZoPWyX2zCue-q8LbY06rL1B7NB6hDcBYHBhY9jppIn/pub?gid=0&single=true&output=csv`
      )
        .then((data) => data.text())
        .then((data) => {
          let list = data.trim().split("\r\n");
          list = list.map(async (data, index) => {
            if (index != 0) {
              const details = data.split(",");
              const job = {};
              await details.map((elem, i) => {
                job[headder[i]] = elem;
              });
              jobList.push(job);
            }
          });
          setJobs(jobList);
        });
    }
  }, []);
  return (
    <div className='bg-slate-200 rounded-lg p-8'>
      {jobs?.map(({ id, dep, badge, role, time, location, type, skills }) => (
        <Job
          key={id}
          id={id}
          dep={dep}
          badge={badge?.split("|")}
          role={role}
          time={time}
          location={location}
          type={type}
          skills={skills?.split("|")}
        />
      ))}
    </div>
  );
}

function Job({ dep, badge, role, location, time, type, skills }) {
  const Department = () => (
    <h1 className='text-2xl font-extrabold text-sky-600 capitalize'>{dep}</h1>
  );
  const Badge = () => (
    <>
      {badge?.map((ele, index) => (
        <div
          key={`${ele} - ${index}`}
          className='px-4 rounded-3xl leading-8 bg-sky-800 text-white uppercase'
        >
          {ele}
        </div>
      ))}
    </>
  );
  const Role = () => (
    <div className='text-slate-700 text-3xl font-extrabold my-4 '>{role}</div>
  );

  const Details = () => (
    <div className='flex gap-2 font-bold'>
      <div className=''>{time}</div>
      <div>.</div>
      <div>{type}</div>
      <div>.</div>
      <div>{location}</div>
    </div>
  );

  const Skills = () => (
    <div className='flex flex-wrap gap-4 mt-8'>
      {skills?.map((ele, index) => (
        <div
          key={`${ele}`}
          className='px-4 rounded-3xl leading-8 bg-blue-400 text-white uppercase'
        >
          #{ele}
        </div>
      ))}
    </div>
  );

  return (
    <div className='bg-white rounded-lg p-8 my-4 overflow-hidden w-full'>
      <div className='flex flex-wrap gap-4'>
        <Department />
        <Badge />
      </div>
      <Role />
      <Details />
      <Skills />
    </div>
  );
}
export default JobOpenings;
